Vue.component("navigation-list-item", {
    props: {
        scrolled: Boolean,
        menuExpanded: Boolean,
    },
    data: function () {
        return {
            itemExpanded: false
        }
    },
    mounted: function () {
    },
    methods: {
    },
});