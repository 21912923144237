Vue.component("accordion", {
    data: function() {
        return {
            activePanel: null,
            isMounted: false,
        };
    },
    computed: {},
    methods: {
        makeActive(itemIndex) {
            this.activePanel === itemIndex
                ? (this.activePanel = null)
                : (this.activePanel = itemIndex);
        },
        beforeEnter: function(el) {
            el.style.height = "0";
        },
        enter: function(el) {
            el.style.height = el.scrollHeight + "px";
        },
        beforeLeave: function(el) {
            el.style.height = el.scrollHeight + "px";
        },
        leave: function(el) {
            el.style.height = "0";
        }
    },
    mounted() {
        this.activePanel = 1;
        this.isMounted = true;
    }
});

Vue.component("collapse-transition", {});